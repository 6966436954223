import * as Sentry from "@sentry/browser"
import { DateTime } from "luxon"
import ky from "ky"

// Developers: turn on and off dev API endpoint here:
const useDevApi = false

// A/B tests status
var abTests = {}

const AB_NOT_CHECKED = 0
const AB_DISABLED = 1
export const AB_ENABLED = 2

const AMP_AB_NONE = 1
const AMP_AB_PARAM_DISABLED = 2
const AMP_AB_PARAM_ENABLED = 3
const AMP_AB_RAND_DISABLED = 4
const AMP_AB_RAND_ENABLED = 5

export const TABLET_MAX_WIDTH = 991
export const MAP_SPLIT_VIEW_WIDTH = 1326

export const LA_AREA_LEVEL = 3

export const TIMEZONE = "Europe/London"

export const GMAP_PUBLISHABLE_KEY = "AIzaSyA9J3K_nqNOmRP-DRBzHdr7mpXF9nVXi7s"

// This detects if the include path of the currently
// running script contains "localhost"
export const isDev = /localhost/.test(document.currentScript.src)

// Make sure API_GATEWAY is never set to a non-prod value in prod:
// DO NOT MODIFY THIS PART
export const API_GATEWAY =
  useDevApi && isDev
    ? "http://127.0.0.1:8080"
    : "https://admin-api-duus6533.ew.gateway.dev"

const sentryTransactionId = Math.random().toString(36).substring(2, 11)
const SENTRY_DSN =
  "https://b8c775b54a7348c48f0316dd4fba38fa@o1021882.ingest.sentry.io/5988022"

export const api = ky.create({ prefixUrl: API_GATEWAY, timeout: false })

export const TIME_PARAMS = ["date", "starttime", "endtime"]

export const WEEKDAY_NUMS = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 0,
}

document.addEventListener("DOMContentLoaded", function () {
  const inIframe = window.self !== window.top
  if (inIframe) {
    const topBar = document.getElementsByClassName("header-row")
    for (let i = 0; i < topBar.length; i++) {
      topBar[i].style.display = "none"
    }
  }
})

document.addEventListener("DOMContentLoaded", function () {
  if (!isDev) return

  const devBar = document.createElement("div")
  devBar.style.position = "fixed"
  devBar.style.bottom = "0"
  devBar.style.left = "100px"
  devBar.style.width = "100%"
  devBar.style.maxWidth = "200px"
  devBar.style.height = "40px"
  devBar.style.backgroundColor = "rgba(255, 255, 0, 0.5)"
  devBar.style.color = "#000"
  devBar.style.display = "flex"
  devBar.style.alignItems = "center"
  devBar.style.justifyContent = "space-between"
  devBar.style.padding = "0 15px"
  devBar.style.fontFamily = "Arial, sans-serif"
  devBar.style.fontSize = "14px"
  devBar.style.zIndex = "9999"
  devBar.style.boxSizing = "border-box"
  devBar.style.borderRadius = "7px 7px 0 0"

  const devText = document.createElement("span")
  devText.textContent = "Dev"

  const closeButton = document.createElement("span")
  closeButton.textContent = "❌"
  closeButton.style.cursor = "pointer"
  closeButton.addEventListener("click", () => {
    document.body.removeChild(devBar)
  })

  devBar.appendChild(devText)
  devBar.appendChild(closeButton)
  document.body.appendChild(devBar)
})

// https://stackoverflow.com/a/75988895
export const debounce = (callback, wait) => {
  let timeoutId = null
  return (...args) => {
    window.clearTimeout(timeoutId)
    timeoutId = window.setTimeout(() => {
      callback(...args)
    }, wait)
  }
}

// Sometimes the browser calls popstate on load, so this is used to avoid
// an infinite loop.
var pushStateCount = 0

Promise.deferred = promiseDeferred

export function promiseDeferred() {
  let resolve
  let reject
  const p = new Promise((_resolve, _reject) => {
    resolve = _resolve
    reject = _reject
  })
  p.resolve = resolve
  p.reject = reject
  return p
}

export const sleep = (ms) => new Promise((r) => setTimeout(r, ms))

export function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n)
}

export function roundToMaxDp(num, dp) {
  let result = +(Math.round(num + "e+" + dp) + "e-" + dp)
  if (Number.isInteger(result)) {
    return result.toFixed(0)
  } else {
    return result.toFixed(dp)
  }
}

export function getSearchParams() {
  return new URLSearchParams(window.location.search)
}

export function initSentry() {
  try {
    Sentry.init({
      dsn: SENTRY_DSN,
      beforeSend(event, hint) {
        if (window.location.pathname === "/booking") {
          // Use a different fingerprint for errors on the confirmation page,
          // so they don't get grouped with other errors
          event.fingerprint = [
            `Booking_${event.exception.values[0].type}_${event.exception.values[0].value}`,
          ]
        }
        return event
      },
      environment: isDev ? "development" : "production",
    })
    Sentry.configureScope((scope) => {
      scope.setTag("transaction_id", sentryTransactionId)
    })
  } catch (err) {}
  // initSentry runs before the analytics scripts (e.g. Inspectlet), so
  // provide a way to re-initialise Sentry
  window.SharesyInitSentry = function () {
    try {
      __insp.push(["tagSession", { sentryID: sentryTransactionId }])
    } catch (err) {}
  }
}

export function logToSentry(err, contextDict) {
  try {
    if (contextDict) {
      Sentry.captureException(err, contextDict)
    } else {
      Sentry.captureException(err)
    }
  } catch (err) {
    console.error("Sentry.captureException failed")
  }
}

var promiseAmplitude = Promise.deferred()

export function amplitudeInit(instance, pageviewName, eventProps) {
  let eventProperties = {}
  if (eventProps) {
    eventProperties = eventProps
  }
  try {
    // Because Amplitude can be enabled well after the page loads (due to cookie banner), we
    // save A/B test events and execute them when Amplitude loads.
    sendSavedAmplitudeAbTests()
  } catch (err) {
    console.error(err)
  }
  eventProperties["URL"] = window.location.href
  instance.logEvent(pageviewName, eventProperties)
  promiseAmplitude.resolve()
}

export function logAmplitudeEvent(eventName, eventProps) {
  // Wait until amplitudeOnLoad has run
  promiseAmplitude.then(() => {
    let eventProperties = {}
    if (eventProps) {
      eventProperties = eventProps
    }
    try {
      eventProperties["URL"] = window.location.href
    } catch (err) {}
    try {
      for (const [key, value] of Object.entries(abTests)) {
        if (value.status === AB_DISABLED) {
          eventProperties[value.friendlyName + " Status"] = "Disabled"
        } else if (value.status === AB_ENABLED) {
          eventProperties[value.friendlyName + " Status"] = "Enabled"
        }
      }
    } catch (err) {}
    try {
      eventProperties["Sentry Transaction ID"] = sentryTransactionId
    } catch (err) {}
    try {
      amplitude.getInstance().logEvent(eventName, eventProperties)
    } catch (err) {}
  })
}

/* Rules:
*    - Check for oneOffOverride - normally this isn't set, but if it is take
       this value and don't set localStorage or log to Amplitude
*    - Check for query param override - if so it takes precedence
*    - Check for existing value in localStorage - if none then random 
       generate 0 or 1
*    - Try to log to Amplitude
*/
export function initAbTest(
  abTest,
  friendlyName,
  queryParamOverride,
  oneOffOverride = -1
) {
  abTests[abTest] = {
    status: AB_NOT_CHECKED,
    friendlyName: friendlyName,
    amplitudeStatus: AMP_AB_NONE,
    queryParamOverride: queryParamOverride,
    oneOffOverride: oneOffOverride,
  }

  if (oneOffOverride > -1) {
    abTests[abTest].status = oneOffOverride
  } else {
    try {
      let lsAbTest = localStorage.getItem(abTest)
      if (lsAbTest === "enabled") {
        abTests[abTest].status = AB_ENABLED
      } else if (lsAbTest === "disabled") {
        abTests[abTest].status = AB_DISABLED
      }
    } catch (err) {
      logToSentry(err)
    }
    let newStatus = AB_NOT_CHECKED
    try {
      let searchParams = getSearchParams()
      if (searchParams.has(queryParamOverride)) {
        let queryParamValue = searchParams.get(queryParamOverride)
        if (queryParamValue === "0" || queryParamValue === "false") {
          newStatus = AB_DISABLED
        } else {
          newStatus = AB_ENABLED
        }
      }
    } catch (err) {
      logToSentry(err)
    }
    try {
      if (
        newStatus !== AB_NOT_CHECKED &&
        newStatus !== abTests[abTest].status
      ) {
        // Log if query param set or changed the value
        if (newStatus === AB_ENABLED) {
          abTests[abTest].amplitudeStatus = AMP_AB_PARAM_ENABLED
          localStorage.setItem(abTest, "enabled")
        } else {
          abTests[abTest].amplitudeStatus = AMP_AB_PARAM_DISABLED
          localStorage.setItem(abTest, "disabled")
        }
        abTests[abTest].status = newStatus
      } else if (abTests[abTest].status === AB_NOT_CHECKED) {
        // Log if status hasn't been set yet by query param or local storage
        let oneOrZero = Math.random() >= 0.5 ? 1 : 0
        if (oneOrZero === 0) {
          abTests[abTest].status = AB_DISABLED
          abTests[abTest].amplitudeStatus = AMP_AB_RAND_DISABLED
          localStorage.setItem(abTest, "disabled")
        } else {
          abTests[abTest].status = AB_ENABLED
          abTests[abTest].amplitudeStatus = AMP_AB_RAND_ENABLED
          localStorage.setItem(abTest, "enabled")
        }
      }
    } catch (err) {
      logToSentry(err)
    }
    // If Amplitude hasn't loaded yet (e.g. because cookie banner not accepted) this will
    // error then sendAmplitudeAbTest() will run during amplitudeOnLoad().
    try {
      sendAmplitudeAbTest(abTest)
    } catch (err) {}
  }

  return abTests[abTest].status
}

// userProperties is e.g. {prop1: value1, prop2: value2}
function setAmplitudeUserProperties(userProperties) {
  amplitude.getInstance().setUserProperties(userProperties)
}

function sendAmplitudeAbTest(abTest) {
  if (abTests[abTest].amplitudeStatus !== AMP_AB_NONE) {
    let amplitudeProps = {}
    if (abTests[abTest].amplitudeStatus === AMP_AB_PARAM_DISABLED) {
      amplitudeProps[abTests[abTest].friendlyName] = "Disabled"
      amplitudeProps[abTests[abTest].friendlyName + " Set By"] =
        "Query Parameter"
      setAmplitudeUserProperties(amplitudeProps)
      logAmplitudeEvent(abTests[abTest].friendlyName + " Set", amplitudeProps)
    } else if (abTests[abTest].amplitudeStatus === AMP_AB_PARAM_ENABLED) {
      amplitudeProps[abTests[abTest].friendlyName] = "Enabled"
      amplitudeProps[abTests[abTest].friendlyName + " Set By"] =
        "Query Parameter"
      setAmplitudeUserProperties(amplitudeProps)
      logAmplitudeEvent(abTests[abTest].friendlyName + " Set", amplitudeProps)
    } else if (abTests[abTest].amplitudeStatus === AMP_AB_RAND_DISABLED) {
      amplitudeProps[abTests[abTest].friendlyName] = "Disabled"
      amplitudeProps[abTests[abTest].friendlyName + " Set By"] = "A/B Test"
      setAmplitudeUserProperties(amplitudeProps)
      logAmplitudeEvent(abTests[abTest].friendlyName + " Set", amplitudeProps)
    } else if (abTests[abTest].amplitudeStatus === AMP_AB_RAND_ENABLED) {
      amplitudeProps[abTests[abTest].friendlyName] = "Enabled"
      amplitudeProps[abTests[abTest].friendlyName + " Set By"] = "A/B Test"
      setAmplitudeUserProperties(amplitudeProps)
      logAmplitudeEvent(abTests[abTest].friendlyName + " Set", amplitudeProps)
    }
    abTests[abTest].amplitudeStatus = AMP_AB_NONE
  }
}

// Send to Amplitude for all entries where oneOffOverride is not set
function sendSavedAmplitudeAbTests() {
  for (const [key, value] of Object.entries(abTests)) {
    sendAmplitudeAbTest(key)
  }
}

/**
 * Update the URL parameters and perform related logic.
 *
 * If `state` is set, it will be used to call `window.history.pushState`.
 *
 * If `updateLocalStorage` is set, the param will be saved by `localStorage.setItem`.
 * @param {string} paramName
 * @param {string} paramValue
 * @param {object | boolean} state
 * @param {boolean} updateLocalStorage
 */
export function updateUrlParam(
  paramName,
  paramValue,
  state,
  updateLocalStorage
) {
  const url = new URL(window.location.href)
  url.searchParams.set(paramName, paramValue)
  if (state) {
    pushStateCount++
    window.history.pushState(state, "", url)
  } else {
    window.history.replaceState(null, null, url)
  }
  if (updateLocalStorage) {
    localStorage.setItem(paramName, paramValue)
  }
}

export function deleteUrlParam(paramName) {
  let url = new URL(window.location.href)
  url.searchParams.delete(paramName)
  window.history.replaceState(null, null, url)
}

export function addUrlParamToLink(element, paramName, paramValue) {
  let url = new URL(element.href)
  let searchParams = new URLSearchParams(url.search)
  searchParams.set(paramName, paramValue)
  url.search = searchParams
  element.href = url.href
}

export function deleteUrlParamFromLink(element, paramName) {
  let url = new URL(element.href)
  url.searchParams.delete(paramName)
  element.href = url.href
}

export function pushStateIncrement() {
  pushStateCount++
}

// If this returns true, caller should check e.state - if it exists caller
// can change back to that state, or reload if no state is saved.
// If this returns false all the pushStates have already been 'popped' so the
// caller shouldn't do anything.
export function popStateCheck() {
  if (pushStateCount > 0) {
    pushStateCount--
    return true
  } else {
    return false
  }
}

// E.g. turn "north-london" into "North London"
export function capitalisedNameFromSlug(slug) {
  let name = slug.replace(/-/g, " ")
  return name.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
}

// E.g. "Chalgrove Gardens, N3 2WQ" becomes "Chalgrove Gardens, N3"
export function removeRightPartOfPostcode(address) {
  let truncatedText = address
  let lastCommaPos = address.lastIndexOf(",")
  let postcodeSpacePos = address.substring(lastCommaPos + 2).indexOf(" ")
  if (postcodeSpacePos > 0) {
    let leftPostcode
    if (postcodeSpacePos > 0) {
      leftPostcode = address.substring(
        lastCommaPos + 2,
        lastCommaPos + 2 + postcodeSpacePos
      )
    } else {
      leftPostcode = address.substring(lastCommaPos + 2)
    }
    truncatedText = address.substring(0, lastCommaPos) + ", " + leftPostcode
  }
  return truncatedText
}

export function capitaliseFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

/**
 * Check that the URL param value from localStorage is not invalid.
 * If it is valid return the value else return null.
 *
 * Date: checks that the value is a valid date str and at least 4 days in the future
 *
 * Time: checks that the value is a number representing a valid 24hr time
 * @param {string} value
 * @param {string} valueType
 */
export function validateStoredParam(value, valueType) {
  if (!value) {
    return null
  }
  try {
    switch (valueType) {
      case "date":
        const fourDaysFromNow = new Date()
        fourDaysFromNow.setDate(fourDaysFromNow.getDate() + 4)
        const paramDate = new Date(value)
        if (isNaN(paramDate.getTime())) {
          // https://stackoverflow.com/a/38182068
          throw new Error("Invalid date was saved to local storage.")
        }
        // reset hours so that only the dates are considered in the operation
        fourDaysFromNow.setHours(0, 0, 0, 0)
        paramDate.setHours(0, 0, 0, 0)
        if (fourDaysFromNow > paramDate) {
          wipeLocalStorageValues()
          return null
        }
        return value
      case "time":
      case "starttime":
      case "endtime":
        const intVal = Number(value)
        if (isNaN(intVal)) {
          throw new Error("Invalid time was saved to local storage.")
        }
        return intVal >= 0 && intVal < 2400 ? value : null
      default:
        wipeLocalStorageValues()
        return null
    }
  } catch (e) {
    wipeLocalStorageValues()
    return null
  }
}

/**
 * Add click listeners to expand and collapse FAQ items.
 *
 * @param {string} amplitudeEventPrefix The Amplitude event names will start with this.
 */
export function faqExpandCollapse(amplitudeEventPrefix) {
  for (const faq of document.getElementsByClassName("faq-row")) {
    faq.addEventListener("click", (e) => {
      let faqHeading =
        e.currentTarget.getElementsByClassName("faq-heading-white")[0].innerText
      let downArrow = e.currentTarget.getElementsByClassName("down-arrow")[0]
      let upArrow = e.currentTarget.getElementsByClassName("up-arrow")[0]
      if (getComputedStyle(upArrow).display === "none") {
        upArrow.style.display = "block"
        downArrow.style.display = "none"
        e.currentTarget.nextElementSibling.style.display = "block"
        logAmplitudeEvent(amplitudeEventPrefix + " Open", { FAQ: faqHeading })
      } else {
        upArrow.style.display = "none"
        downArrow.style.display = "block"
        e.currentTarget.nextElementSibling.style.display = "none"
        logAmplitudeEvent(amplitudeEventPrefix + " Close", { FAQ: faqHeading })
      }
    })
  }
}

function wipeLocalStorageValues() {
  localStorage.removeItem("date")
  localStorage.removeItem("starttime")
  localStorage.removeItem("endtime")
}

// Generate 06:00, 06:30, 07:00, etc.
export function generateTimes(startHour, endHour, endHourNextDay) {
  let endHourExtended = endHourNextDay ? endHour + 24 : endHour
  let times = []
  for (let h = startHour; h <= endHourExtended; h++) {
    let hour = h >= 24 ? h - 24 : h
    hour = hour < 10 ? "0" + hour : "" + hour
    times.push(`${hour}:00`)
    if (h < endHourExtended) {
      times.push(`${hour}:30`)
    }
  }
  return times
}

export function valueToBool(value) {
  return value.toString().toLowerCase() == "true" || value === true
}

export function timeDiffHoursMins(startDateTime, endDateTime) {
  let diff = endDateTime.diff(startDateTime, ["hours", "minutes"])
  let result = ""
  if (diff.hours > 1) {
    result += diff.hours + " hours "
  } else if (diff.hours == 1) {
    result += diff.hours + " hour "
  }
  if (diff.minutes > 0) {
    result += diff.minutes + " minutes"
  }
  return result.trimEnd()
}

export function calculateDateTime(date, time, timeOnNextDay) {
  let datetime = DateTime.fromISO(date + "T" + time, {
    zone: TIMEZONE,
  })
  if (timeOnNextDay) {
    datetime = datetime.plus({ days: 1 })
  }
  return datetime
}

/**
 * Use this instead of jsDate.toISOString().split('T')[0] because that will
 * first convert to UTC.
 */
export function jsDateToIso(jsDate) {
  let year = jsDate.getFullYear()
  // getMonth() returns a zero-based month, so add 1
  let month = jsDate.getMonth() + 1
  let day = jsDate.getDate()
  // Pad with leading zeros if necessary
  month = month < 10 ? "0" + month : month
  day = day < 10 ? "0" + day : day
  return `${year}-${month}-${day}`
}

export function calculateHourlyPrice(price, numHours, numMins) {
  return roundToMaxDp(parseFloat(price) / (numHours + numMins / 60), 2)
}

export function checkTimesAreAvailable(
  startTimestamp,
  endTimestamp,
  availableTimes
) {
  if (!availableTimes) {
    return false
  }
  const duration = (endTimestamp - startTimestamp) / 1000 / 60 / 60
  let matchingTimes = availableTimes.filter(
    (t) =>
      t.start_time_utc <= startTimestamp &&
      t.end_time_utc >= endTimestamp &&
      duration >= t.min_at_hours
  )
  return matchingTimes.length > 0
}

export function addButtonClassAndTooltip(timeButton, className, reason) {
  timeButton.classList.add(className)
  if (reason != "") {
    timeButton.setAttribute("data-reason", reason)
    timeButton.addEventListener("mouseover", displayTooltip)
    timeButton.addEventListener("mouseout", removeTooltip)
  }
}

export function removeButtonClassAndTooltip(timeButton, className) {
  timeButton.classList.remove(className)
  timeButton.removeEventListener("mouseover", displayTooltip)
  timeButton.removeEventListener("mouseout", removeTooltip)
  timeButton.removeAttribute("data-reason")
}

function displayTooltip(e) {
  let tt = document.createElement("span")
  e.currentTarget.appendChild(tt)
  tt.setAttribute("id", "tooltip")
  tt.classList.add("tooltip")
  let txt = document.createTextNode(e.currentTarget.dataset.reason)
  tt.appendChild(txt)
}

function removeTooltip(e) {
  e.currentTarget.querySelector(".tooltip").remove()
}

export function clickTime(time) {
  let timeButtons = document.getElementsByClassName("time-button")
  for (let i = 0; i < timeButtons.length; i++) {
    if (timeButtons[i].innerText === time) {
      timeButtons[i].click()
      break
    }
  }
}

/**
 * Search for checkout question form fields and extract the IDs saved.
 * @param {string} attributeName The element attribute to search on. Either "question_id" or "acuity_field".
 * @returns {number[]}
 */
export function getQuestionIdsFromElements(attributeName) {
  let questionFormFields = document.querySelectorAll("[" + attributeName + "]")
  return Array.from(questionFormFields).map((elem) =>
    parseInt(elem.getAttribute(attributeName))
  )
}

/**
 * Return the slug within a space URL, by returning the last part of the URL
 * before the query string.
 * @param {string} url E.g. https://www.sharesy.com/s/the-halley-nova?date=2024-02-01
 * @returns {string} E.g. the-halley-nova
 */
export function getSlugFromUrl(url) {
  url = url.split("?")[0]
  url = url.split("/")
  return url[url.length - 1]
}

export function addStyleTag(styleString) {
  const style = document.createElement("style")
  style.textContent = styleString
  document.head.appendChild(style)
}

export function escapeHTML(unsafeText) {
  let tempDiv = document.createElement("div")
  tempDiv.innerText = unsafeText
  return tempDiv.innerHTML
}
