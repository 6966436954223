import { Loader } from "@googlemaps/js-api-loader"
import flatpickr from "flatpickr"
import { DateTime } from "luxon"
import * as util from "./util"

/* To locally debug this script via Webflow the best option I've found is to
add two scripts in Webflow:
<script src="http://localhost:3000/????.bundle.js"></script>
<script src="angry-boyd-1669aa.netlify.app/????.bundle.js" crossorigin="anonymous"></script>
The below check ensures that if localhost loads successfully the second
(production) script won't run.
Initially I tried to add the script dynamically in Webflow but that didn't
work - I needed it to run immediately but it wouldn't. */
if (typeof SharesyWebflow === "undefined") {
  util.initSentry()

  window.amplitudeOnLoad = function (instance) {
    util.amplitudeInit(instance, "New Home Page Viewed")
  }

  window.tfQuoteQuestionChanged = function (data) {
    SharesyWebflow.logAmplitudeEvent("Home Quote Typeform Question Change", {
      question: data.ref,
    })
  }
  window.tfQuoteReady = function () {
    SharesyWebflow.logAmplitudeEvent("Home Quote Typeform Ready")
  }
  window.tfQuoteSubmit = function () {
    SharesyWebflow.logAmplitudeEvent("Home Quote Typeform Submit")
  }
  window.tfQuoteClose = function () {
    SharesyWebflow.logAmplitudeEvent("Home Quote Typeform Close")
  }

  // Wrap everything with Revealing Module Pattern
  window.SharesyWebflow = (function () {
    var fp
    var date
    var startTime
    var endTime
    var dateTextLong
    var dateTextShort

    // Time filter status
    const TIME_NONE = 0
    const TIME_START = 1
    const TIME_END = 2
    var timeStatus = TIME_NONE

    var gmapSelectedPlace

    function logAmplitudeEvent(eventName, eventProps) {
      let eventProperties = {}
      if (eventProps) {
        eventProperties = eventProps
      }
      try {
        eventProperties["Selected Date"] =
          document.querySelector(".text-date-input").value
        eventProperties["Start Time"] = startTime
        eventProperties["End Time"] = endTime
      } catch (err) {}
      try {
        const url = new URL(document.getElementById("search-go").href)
        eventProperties["Location"] = url.searchParams.get("loc")
        eventProperties["Area"] = url.searchParams.get("area")
      } catch (err) {}
      util.logAmplitudeEvent(eventName, eventProperties)
    }

    async function addUrlParamToSearchButton(paramName, paramValue) {
      const searchButton = document.getElementById("search-go")
      if (util.TIME_PARAMS.includes(paramName)) {
        localStorage.setItem(paramName, paramValue)
      }
      util.addUrlParamToLink(searchButton, paramName, paramValue)
    }
    async function deleteUrlParamFromSearchButton(paramName) {
      const searchButton = document.getElementById("search-go")
      util.deleteUrlParamFromLink(searchButton, paramName)
    }

    async function gmapMainRun() {
      // Handle location selected via autocomplete
      let locationSearch = document.getElementById("location-search")
      const options = {
        componentRestrictions: { country: "uk" },
        types: ["geocode"],
      }
      try {
        let autocomplete = new google.maps.places.Autocomplete(
          locationSearch,
          options
        )
        google.maps.event.addListener(
          autocomplete,
          "place_changed",
          function () {
            let place = autocomplete.getPlace()
            gmapSelectedPlace = place.place_id
            if (gmapSelectedPlace) {
              gmapMakeRequest()
              const eventProperties = {
                "Place Name": place.name,
                Latitude: place.geometry.location.lat(),
                Longitude: place.geometry.location.lng(),
              }
              logAmplitudeEvent(
                "Select Autocomplete Location (Home)",
                eventProperties
              )
            }
          }
        )
      } catch (err) {
        logAmplitudeEvent(err)
      }
      try {
        // Prevent form submission if the user presses Enter within the input
        locationSearch.addEventListener("keydown", function (event) {
          if (event.code === "Enter") {
            event.preventDefault()
          }
        })
      } catch (err) {
        logAmplitudeEvent(err)
      }
    }

    async function gmapMakeRequest() {
      if (gmapSelectedPlace) {
        let gmapRequest = {
          placeId: gmapSelectedPlace,
          fields: ["geometry", "name"],
        }
        let service = new google.maps.places.PlacesService(
          document.createElement("div")
        )
        await service.getDetails(gmapRequest, gmapGetDetailsCallback)
      }
    }

    async function gmapGetDetailsCallback(place, status) {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        document.getElementById("location-button-text").innerText = place.name
        addUrlParamToSearchButton("loc", place.name)
        deleteUrlParamFromSearchButton("area")
        deleteUrlParamFromSearchButton("nearme")
        closeModals()
      }
    }

    // Fired each time a date is selected in the date picker
    function flatpickrDateChange(selectedDates) {
      if (selectedDates.length > 0) {
        dateTextLong = selectedDates[0].toLocaleDateString("en-GB", {
          weekday: "short",
          month: "short",
          day: "numeric",
        })
        dateTextShort = selectedDates[0].toLocaleDateString("en-GB", {
          month: "short",
          day: "numeric",
        })
        // Need to remove timezone offset before splitting ISO string
        // See: https://stackoverflow.com/a/50130338
        let d = new Date(
          selectedDates[0].getTime() -
            selectedDates[0].getTimezoneOffset() * 60000
        )
        date = d.toISOString().split("T")[0]
        setDateButtonText()
        try {
          logAmplitudeEvent("Select Date (Home)")
        } catch (err) {
          util.logToSentry(err)
        }
        document.getElementById("time-modal").style.display = "flex"
      }
    }

    function setDateButtonText() {
      let dateText = document.getElementById("date-text")
      if (startTime && endTime) {
        dateText.innerText = dateTextShort + " • " + startTime + "-" + endTime
        dateText.style.width = "150px"
      } else {
        dateText.innerText = dateTextLong
      }
      addUrlParamToSearchButton("date", date)
    }

    function timeButtonClick(e) {
      let selectedButtons = document.getElementsByClassName(
        "time-button selected"
      )
      if (timeStatus === TIME_NONE) {
        timeStatus = TIME_START
        e.currentTarget.classList.add("selected")
        document.getElementById("time-modal-header").innerText =
          "Choose an end time"
        document.getElementById("time-modal-clear").style.display = "block"

        startTime = selectedButtons[0].innerText
        let startBtnTime = DateTime.fromISO(date + "T" + startTime, {
          zone: util.TIMEZONE,
        })
        const timeButtons = document.getElementsByClassName("time-button")
        for (const tb of timeButtons) {
          if (tb.innerText < startTime) {
            tb.classList.add("disabled")
          } else if (tb.innerText > startTime) {
            let btnTime = DateTime.fromISO(date + "T" + tb.innerText, {
              zone: util.TIMEZONE,
            })
            tb.addEventListener("mouseover", mouseoverSelectOtherTimes)
            tb.addEventListener("mouseout", mouseoutSelectOtherTimes)
          }
        }
        document.getElementById("time-modal").style.display = "none"
        setTimeout(function () {
          document.getElementById("time-modal").style.display = "flex"
        }, 300)
        try {
          logAmplitudeEvent("Select Start Time (Home)")
        } catch (err) {}
        addUrlParamToSearchButton("starttime", startTime.replace(":", ""))
      } else if (timeStatus === TIME_START) {
        startTime = selectedButtons[0].innerText
        endTime = e.currentTarget.innerText
        if (endTime > startTime) {
          timeStatus = TIME_END
          const timeButtons = document.getElementsByClassName("time-button")
          for (const tb of timeButtons) {
            if (tb.innerText < endTime && tb.innerText > startTime) {
              tb.classList.add("auto-selected")
            }
          }
          removeStartTimeEvents()
          e.currentTarget.classList.add("selected")
          document.getElementById("time-modal-header").innerText =
            "Choose a start time"
          document.getElementById("time-modal-clear").style.display = "block"
          try {
            logAmplitudeEvent("Select End Time (Home)")
          } catch (err) {}
          addUrlParamToSearchButton("endtime", endTime.replace(":", ""))
          setDateButtonText()
          closeModals(true, false)
        }
      } else if (timeStatus === TIME_END) {
        timeClear()
        timeStatus = TIME_NONE
        timeButtonClick(e)
      }
    }

    function timeClear() {
      const timeButtons = document.getElementsByClassName("time-button")
      for (const tb of timeButtons) {
        tb.classList.remove("selected")
        tb.classList.remove("auto-selected")
      }
      document.getElementById("time-modal-header").innerText =
        "Choose a start time"
      document.getElementById("time-modal-clear").style.display = "none"
      timeStatus = TIME_NONE
      try {
        logAmplitudeEvent("Clear Times (Home)")
      } catch (err) {}

      startTime = undefined
      endTime = undefined
      removeStartTimeEvents()
      setDateButtonText()
      deleteUrlParamFromSearchButton("starttime")
      deleteUrlParamFromSearchButton("endtime")
    }

    function timeClose() {
      if (timeStatus === TIME_START) {
        timeClear()
      }
      closeModals()
      try {
        logAmplitudeEvent("Close Time Modal (Home)")
      } catch (err) {}
    }

    // If just a start time is selected, as the user mouses over potential end times, colour all the times in between.
    function mouseoverSelectOtherTimes(e) {
      if (timeStatus === TIME_START) {
        let mouseoverTime = e.currentTarget.innerText
        const timeButtons = document.getElementsByClassName("time-button")
        for (const tb of timeButtons) {
          if (tb.innerText < mouseoverTime && tb.innerText > startTime) {
            tb.classList.add("auto-selected")
          }
        }
      }
    }

    function mouseoutSelectOtherTimes(e) {
      if (timeStatus === TIME_START) {
        let mouseoverTime = e.currentTarget.innerText
        const timeButtons = document.getElementsByClassName("time-button")
        for (const tb of timeButtons) {
          if (tb.innerText < mouseoverTime && tb.innerText > startTime) {
            tb.classList.remove("auto-selected")
          }
        }
      }
    }

    function removeStartTimeEvents() {
      const timeButtons = document.getElementsByClassName("time-button")
      for (const tb of timeButtons) {
        tb.classList.remove("disabled")
        tb.removeEventListener("mouseover", mouseoverSelectOtherTimes)
        tb.removeEventListener("mouseout", mouseoutSelectOtherTimes)
      }
    }

    function closeModals(closeDate = true, closeLocation = true) {
      document.getElementById("popup-background").style.display = "none"
      if (closeLocation) {
        document.getElementById("location-modal").style.display = "none"
        document
          .getElementById("location-button")
          .classList.remove("highlighted")
      }
      if (closeDate) {
        document.getElementById("time-modal").style.display = "none"
        document.getElementById("date-button").classList.remove("highlighted")
        document.getElementById("search-block").classList.remove("highlighted")
        // If you click the Choose date button a second time date can reopen after calling close, so add a delay
        setTimeout(function () {
          fp.close()
        }, 100)
      }
    }

    function init() {
      // Highlight date button when clicked
      try {
        document
          .getElementById("date-button")
          .addEventListener("click", function (e) {
            if (
              !document
                .getElementById("date-button")
                .classList.contains("highlighted")
            ) {
              closeModals(false, true)
              document
                .getElementById("date-button")
                .classList.add("highlighted")
              document
                .getElementById("search-block")
                .classList.add("highlighted")
              document.getElementById("popup-background").style.display =
                "block"
              logAmplitudeEvent("Open Choose Date (Home)")
            } else {
              closeModals()
            }
          })
      } catch (err) {
        util.logToSentry(err)
      }

      // Flatpickr calendar
      try {
        let monthsFromNow = new Date()
        monthsFromNow.setDate(monthsFromNow.getDate() + 365)
        let fourDaysFromNow = new Date()
        fourDaysFromNow.setDate(fourDaysFromNow.getDate() + 4)

        fp = flatpickr(".flatpickr", {
          enableTime: false,
          wrap: true,
          minDate: fourDaysFromNow,
          maxDate: monthsFromNow,
          disableMobile: "true",
          monthSelectorType: "static",
          /* If the calendar auto opens we don't want it to close when the user 
            interacts with the cookie consent modal or scrolls on mobile  */
          ignoredFocusElements: [window.document.body],
          locale: {
            firstDayOfWeek: 1, // start week on Monday
          },
          onChange: function (selectedDates, dateStr, instance) {
            flatpickrDateChange(selectedDates)
          },
          position: "below",
        })
      } catch (err) {
        util.logToSentry(err)
      }

      // search localStorage for time values and use these in the UI/search link if found
      if (
        util.initAbTest("useStoredDates", "Use Stored Dates", "storeddates") ===
        util.AB_ENABLED
      ) {
        const storedDate = localStorage.getItem("date")
        if (util.validateStoredParam(storedDate, "date")) {
          date = storedDate
          const d = new Date(date)
          dateTextLong = d.toLocaleDateString("en-GB", {
            weekday: "short",
            month: "short",
            day: "numeric",
          })
          dateTextShort = d.toLocaleDateString("en-GB", {
            month: "short",
            day: "numeric",
          })
        }
        const storedStartTime = localStorage.getItem("starttime")
        const storedEndTime = localStorage.getItem("endtime")
        if (
          util.validateStoredParam(storedStartTime, "time") &&
          util.validateStoredParam(storedEndTime, "time")
        ) {
          startTime = `${storedStartTime.slice(0, 2)}:${storedStartTime.slice(
            2
          )}`
          endTime = `${storedEndTime.slice(0, 2)}:${storedEndTime.slice(2)}`
        }
        if (dateTextLong) {
          setDateButtonText()
        }
        if (startTime && endTime) {
          addUrlParamToSearchButton("starttime", storedStartTime)
          addUrlParamToSearchButton("endtime", storedEndTime)
        }
      }

      // Add listeners to the time modal buttons.
      try {
        const timeButtons = document.getElementsByClassName("time-button")
        for (const tb of timeButtons) {
          tb.addEventListener("click", timeButtonClick)
        }
        let elem = document.getElementById("time-modal-clear")
        elem.addEventListener("click", timeClear)
        elem = document.getElementById("time-modal-close")
        elem.addEventListener("click", timeClose)
      } catch (err) {
        util.logToSentry(err)
      }

      // Display location modal
      try {
        document
          .getElementById("location-button")
          .addEventListener("click", function (e) {
            if (
              !document
                .getElementById("location-button")
                .classList.contains("highlighted")
            ) {
              closeModals(true, true, false)
              document.getElementById("location-modal").style.display = "flex"
              document.getElementById("location-search").focus()
              document
                .getElementById("location-button")
                .classList.add("highlighted")
              document
                .getElementById("search-block")
                .classList.add("highlighted")
              document.getElementById("popup-background").style.display =
                "block"
              logAmplitudeEvent("Open Location (Home)")
            } else {
              closeModals()
            }
          })
      } catch (err) {
        util.logToSentry(err)
      }

      // London location
      try {
        document
          .getElementById("area-london")
          .addEventListener("click", function () {
            document.getElementById("location-button-text").innerText = "London"
            deleteUrlParamFromSearchButton("area")
            deleteUrlParamFromSearchButton("loc")
            deleteUrlParamFromSearchButton("nearme")
            closeModals()
            logAmplitudeEvent("Select London (Home)")
          })
      } catch (err) {
        util.logToSentry(err)
      }

      // Areas
      try {
        const areas = document
          .getElementById("collection-list-area")
          .getElementsByClassName("icon-and-text-block-area")
        for (const area of areas) {
          area.addEventListener("click", async function (e) {
            let area = e.currentTarget.querySelector(".icon-text").innerText
            document.getElementById("location-button-text").innerText = area
            let slug = e.currentTarget.querySelector(".slug-hidden").innerText
            addUrlParamToSearchButton("area", slug)
            deleteUrlParamFromSearchButton("loc")
            deleteUrlParamFromSearchButton("nearme")
            closeModals()
            logAmplitudeEvent("Select Area (Home)")
          })
        }
      } catch (err) {
        util.logToSentry(err)
      }

      // Close modals when click outside
      try {
        document
          .getElementById("popup-background")
          .addEventListener("click", (e) => {
            if (e.target.id === "popup-background") {
              if (timeStatus === TIME_START) {
                timeClear()
              }
              closeModals()
            }
          })
      } catch (err) {
        util.logToSentry(err)
      }

      // FAQ expand/collapse - exists on the 'near-me' page but not the home page
      try {
        util.faqExpandCollapse("Near Me FAQ")
      } catch (err) {
        util.logToSentry(err)
      }

      try {
        const loader = new Loader({
          apiKey: util.GMAP_PUBLISHABLE_KEY,
          version: "weekly",
          libraries: ["geometry", "places"],
          region: "GB",
        })
        loader.load().then(gmapMainRun)
      } catch (err) {
        util.logToSentry(err)
      }

      try {
        const reviews = document.getElementsByClassName("testimonial-card")
        const starFull = document.getElementById("star-full")
        const starEmpty = document.getElementById("star-empty")
        let reviewTotal = 0
        // Add stars for individual reviews
        for (const r of reviews) {
          let stars = r.querySelector("#stars")
          let reviewScore = parseInt(r.querySelector("#review-score").innerText)
          reviewTotal += reviewScore
          for (let i = 1; i <= 5; i++) {
            if (i <= reviewScore) {
              stars.appendChild(starFull.cloneNode(true))
            } else {
              stars.appendChild(starEmpty.cloneNode(true))
            }
          }
        }
      } catch (err) {
        util.logToSentry(err)
      }
    }

    return {
      init: init,
      logAmplitudeEvent: logAmplitudeEvent,
      gmapMainRun: gmapMainRun,
    }
  })()
}
